<template>
  <div class="classSubject" @click="toDetailSubject">
    <!-- <a-card
      class="cardSubject"
      :headStyle="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${config.apiUrl}/${subject.cover}')`,
        backgroundSize: '100% 100%',
      }"
      :title="subject.nama"
    > -->
    <a-card
      class="cardSubject"
      :headStyle="{
        background: subject.cover ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${config.apiUrl}${subject.cover}')` : `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/${subject.nama}/cover.png')`,
        backgroundSize: '100% 100%',
      }"
      :title="subject.nama"
    >
      <a-row>
        <a-col :span="12">
          <p style="color: #707788">Next Session</p>
          <template v-if="nextSession">
            <h3>{{ nextSession.date }}</h3>
            <h3>{{ nextSession.time }}</h3>
          </template>
          <template v-else>
            <h3>-</h3>
          </template>
        </a-col>
        <a-col :span="12">
          <p style="color: #707788">Latest Assignment</p>
          <template v-if="lastAssignment">
            <h3>
              {{statusAssignment}}
            </h3>
            <p style="font-size: 14px; color: red">
              <a-icon type="hourglass" />
              {{ lastAssignment.deadline }}
            </p>
          </template>
          <template v-else>
            <h3>-</h3>
          </template>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>
<script>
import moment from 'moment'
import config from '@/config'

export default {
  props: ['subject'],
  methods: {
    toDetailSubject() {
      this.$store.commit('subject/SET_STATE', {
        currentSubject: this.subject,
      })
      this.$router.push({ name: 'Detail Subject Student', params: { idSubject: this.subject.id } })
    },
    assignmentCount() {
    },
  },
  data() {
    return {
      tugasDeadlineLastSession: '',
      totalTaskLastSession: null,
      assignmentDone: null,
      config,
    }
  },
  computed: {
    nextSession() {
      const { nextSession } = this.subject
      if (!nextSession.tanggal_sesi) return null
      const date = moment(nextSession.tanggal_sesi, 'YYYY-MM-DD').format('DD MMM YYYY')
      const time = moment(nextSession.time, 'HH:mm:ss').format('HH:mm')
      return {
        date, time,
      }
    },
    lastAssignment() {
      const { lastAssignment } = this.subject
      if (!lastAssignment.deadline) return null
      return {
        ...lastAssignment,
        deadline: moment(lastAssignment.deadline, 'YYYY-MM-DD').format('DD MMM YYYY HH:mm'),
      }
    },
    statusAssignment() {
      if (this.lastAssignment?.submitted) return 'Submitted'

      return 'Waiting to Submit'
    },
  },
  created() {
  },
}
</script>
<style lang="scss">
.classSubject {
  .cardSubject {
    transition: all 0.3s;
    cursor: pointer;
    .ant-card-head-wrapper {
      overflow: hidden;
    }
    // .ant-card-head-title {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   -webkit-line-clamp: 2;
    //   -webkit-box-orient: vertical;
    // }
  }

  .cardSubject:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 0px 20px #0419301a;
    transition: all 0.3s;
  }

  p,
  h3 {
    margin-bottom: 5px !important;
  }

  h3 > span {
    font-family: "Mukta", "sans-serif";
    font-weight: 400;
    color: #707788;
    font-size: 16px;
  }
  .ant-card {
    border: none;
    box-shadow: 0px 0px 10px #0419301a;
    border-radius: 6px;
    min-height: 290px;
  }

  .ant-card-head {
    min-height: 125px;
    display: flex;
    align-items: center;
    color: white;
    opacity: 1;
    border-radius: 6px;
  }

  .ant-card-head-title {
    font-weight: 700;
    font-size: 1.6rem;
    opacity: 1;
  }
}
</style>
